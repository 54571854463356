@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;600&display=swap');

body {
  margin: 0;
  background-color: #023400;
}
